export const getConfigFromStorage = (step = "", field = "") => {
  let config = localStorage.getItem("config")
    ? JSON.parse(localStorage.getItem("config"))
    : {};
  if (!step || !config[step]) return config;
  if (field && config[step][field]) return config[step][field];
  return config[step];
};

export const updateConfigToStorage = (config = {}) => {
  localStorage.setItem("config", JSON.stringify(config));
};

export const getCurrencyFromStorage = () => {
  return localStorage.getItem("currency");
};

export const updateCurrencyToStorage = (currency) => {
  if (currency) localStorage.setItem("currency", currency);
};

export const getCountryCodeFromStorage = () => {
  return localStorage.getItem("countryCode");
};

export const updateCountryCodeToStorage = (countryCode) => {
  if (countryCode) localStorage.setItem("countryCode", countryCode);
};

export const getLocaleFromStorage = () => {
  return localStorage.getItem("locale");
};

export const updateLocaleToStorage = (locale) => {
  if (locale) localStorage.setItem("locale", locale);
};

export const getClientIdFromStorage = () => {
  return localStorage.getItem("clientId");
};

export const updateClientIdToStorage = (clientId) => {
  if (clientId) localStorage.setItem("clientId", clientId);
};

export const getUserEmailFromStorage = () => {
  return localStorage.getItem("userEmail");
};

export const updateUserEmailToStorage = (userEmail) => {
  if (userEmail) localStorage.setItem("userEmail", userEmail);
};

export const getSessionIdFromStorage = () => {
  return localStorage.getItem("sessionId");
};

export const updateSessionIdToStorage = (sessionId) => {
  if (sessionId) localStorage.setItem("sessionId", sessionId);
};

export const getOfferEndTimeFromStorage = () => {
  return localStorage.getItem("offerEndTime");
};

export const updateOfferEndTimeToStorage = (offerEndTime) => {
  if (offerEndTime) localStorage.setItem("offerEndTime", offerEndTime);
};

export const removeOfferEndTimeFromStorage = () => {
  localStorage.removeItem("offerEndTime");
};
