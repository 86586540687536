import { CHART_PAGE_URL } from "./constants";
import { getConfigFromStorage } from "./storage";

export const getChartPageURL = () => {
  const url = new URL(CHART_PAGE_URL);
  let config = getConfigFromStorage();
  if (!config) return url;
  for (const [stepId, fields] of Object.entries(config)) {
    if (fields) {
      for (const [fieldId, value] of Object.entries(fields)) {
        let underscoredFieldId = fieldId
          .replace(/(?:^|\.?)([A-Z])/g, function (x, y) {
            return "_" + y.toLowerCase();
          })
          .replace(/^_/, "");
        url.searchParams.append(underscoredFieldId, value);
      }
    }
  }

  // Experiment params
  let experiment =
    localStorage.getItem("experiment") &&
    JSON.parse(localStorage.getItem("experiment"));
  if (experiment)
    for (let key in experiment) {
      url.searchParams.append(key, experiment[key]);
    }
  return url;
};
