import { REDIRECTING_PATH, PROCESSING_PATH } from "../util/constants";
import structure from "../data/structure";
import { eventToDatalayer } from "../util/tracking";

export const Survey = (stepPointer, history) => {
  let phase =
    structure.phases.find((phase) => {
      return phase.steps.find(
        (step) => step.slug === stepPointer || step.id === stepPointer
      );
    }) || structure.phases[0];
  let phaseIndex = structure.phases.findIndex((p) => p.id === phase.id);
  let totalPhases = structure.phases.length || 3;

  let steps = [];
  structure.phases.forEach((phase) => {
    phase.steps.forEach((step) => steps.push(step));
  });
  let step =
    steps.find(
      (step) => step.slug === stepPointer || step.id === stepPointer
    ) || steps[0];
  let stepId = step.id;
  let stepIndex = steps.findIndex((step) => step.id === stepId) || 0;
  let phaseStepIndex = phase.steps.findIndex((step) => step.id === stepId) || 0;
  let phaseStepProgress = (phaseStepIndex / phase.steps.length) * 100;

  let showSubmitButton =
    (step.fields &&
      step.fields.some((field) =>
        ["multipleChoice", "text", "date", "time", "location"].includes(
          field.type
        )
      )) ||
    step.showSubmitButton;

  let isFirstStep = stepIndex === 0;

  const navigateToStep = (index) => {
    let changedPath;
    let changedStepSlug = steps[index] && steps[index].slug;
    if (!changedStepSlug) {
      changedPath =
        index > stepIndex ? REDIRECTING_PATH : `/step/${steps[0].slug}/`;
    } else {
      changedPath = `/step/${changedStepSlug}/`;
    }
    let navigatingForward = index > stepIndex;
    if (step.loaderAfter && navigatingForward) {
      localStorage.setItem("redirectAfter", changedPath);
      history.push(PROCESSING_PATH);
    } else {
      history.push(changedPath);
    }
  };

  const navigateToNextStep = () => {
    eventToDatalayer("completeStep", { stepNumber: stepIndex + 1 });
    navigateToStep(stepIndex + 1);
  };

  const navigateToPreviousStep = () => {
    navigateToStep(stepIndex - 1);
  };

  const getFirstStep = () => {
    return steps[0];
  };

  return {
    steps,
    step,
    stepIndex,
    isFirstStep,
    phase,
    phaseIndex,
    phaseStepProgress,
    totalPhases,
    navigateToStep,
    navigateToNextStep,
    navigateToPreviousStep,
    showSubmitButton,
    getFirstStep,
  };
};
