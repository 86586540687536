import React, { useEffect, useState } from "react";

const TextField = ({ id, placeholder, input, className, changeHandler }) => {
  const [value, setValue] = useState(input[id] || "");

  const handleBlur = (e) => {
    setValue(e.target.value);
  };

  const validate = () => {
    return value !== "";
  };

  useEffect(() => {
    let valid = validate();
    changeHandler(id, value, valid);
  }, [value]);

  return (
    <input
      type="text"
      className={`underlined-field ${className || ""}`}
      placeholder={placeholder}
      onChange={handleBlur}
      value={value}
      name={id}
    />
  );
};

export default TextField;
