import TagManager from "react-gtm-module";

export const eventToDatalayer = (eventName, additionalData = {}) => {
  const tagManagerArgs = {
    dataLayer: {
      event: eventName,
      ...additionalData,
    },
  };
  TagManager.dataLayer(tagManagerArgs);
};
