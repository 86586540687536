import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { Survey } from "../../util/survey";
import "./style.scss";
import ProgressSteps from "../ProgressSteps";

const SurveyHeader = ({ className }) => {
  const { slug } = useParams();
  const history = useHistory();
  const survey = Survey(slug, history);
  const { navigateToPreviousStep, isFirstStep } = survey;

  let { phase, phaseIndex, totalPhases, phaseStepProgress } = survey;
  let phaseTitle = phase.title;
  let currentPhaseNumber = phaseIndex;

  const handlePrevClick = () => {
    navigateToPreviousStep();
  };

  return (
    <header className={`survey-header ${className}`}>
      <ProgressSteps
        totalPhases={totalPhases}
        currentStep={currentPhaseNumber}
        progress={phaseStepProgress}
        className="mb-3"
      ></ProgressSteps>
      {!isFirstStep && (
        <div className="survey-header__prev-button" onClick={handlePrevClick}>
          {"<"}
        </div>
      )}
      <div className="d-flex justify-content-center">
        <strong className="text--purple">{phaseTitle}</strong>
      </div>
    </header>
  );
};

export default SurveyHeader;
