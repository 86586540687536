import React from "react";
import "./style.scss";

const ProgressSteps = ({ totalPhases, currentStep, progress, className }) => {
  let steps = [];

  for (let i = 0; i <= totalPhases; i++) {
    let stepClass = "progress-steps__step";
    let percentage = "100%";

    if (i === 0) {
      stepClass += " progress-steps__step--first";
    }
    if (i <= currentStep) {
      stepClass += " is-done";
    }
    if (i === parseInt(currentStep)) {
      percentage = progress + "%";
    } else if (i > currentStep) {
      percentage = "0%";
    }

    steps.push(
      <React.Fragment key={i}>
        <li className={stepClass} data-step={i}></li>
        {i < totalPhases && (
          <li className="progress-steps__bar">
            <div
              className="progress-steps__bar-percentage"
              style={{ width: percentage }}
            ></div>
          </li>
        )}
      </React.Fragment>
    );
  }
  return <ol className={`progress-steps ${className}`}>{steps}</ol>;
};

export default ProgressSteps;
