import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import App from "./App";
import hotjar from "react-hotjar";

// const initHotjar = () => {
//   if (process.env.NODE_ENV === "production") {
//     hotjar.initialize(process.env.REACT_APP_HOTJAR_ID);
//   }
// };
// initHotjar();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
