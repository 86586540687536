import React, { useEffect, useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import "./style.css";

const LocationField = ({ className, changeHandler, input }) => {
  const [address, setAddress] = useState(input.address || "");
  const [longitude, setLongitude] = useState(input.longitude || "");
  const [latitude, setLatitude] = useState(input.latitude || "");

  const handleChange = (address) => {
    setAddress(address);
  };

  const handleSelect = async (address) => {
    try {
      const results = await geocodeByAddress(address);
      if (results[0]) {
        const latlng = await getLatLng(results[0]);
        setAddress(address);
        if (latlng.lng) setLongitude(latlng.lng);
        if (latlng.lat) setLatitude(latlng.lat);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const validate = () => {
    return address && longitude && latitude;
  };

  useEffect(() => {
    let valid = validate();
    changeHandler({ address, longitude, latitude }, valid);
  }, [address, longitude, latitude]);

  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <>
          <input
            type="text"
            {...getInputProps({
              placeholder: "Search Places ...",
              className: `underlined-field location-search-input ${
                className || ""
              }`,
            })}
          />
          <div className="autocompletion">
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion, index) => {
              const className = suggestion.active
                ? "autocompletion__suggestion--active"
                : "autocompletion__suggestion";
              return (
                <div
                  key={index}
                  {...getSuggestionItemProps(suggestion, {
                    className,
                  })}
                >
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </>
      )}
    </PlacesAutocomplete>
  );
};

export default LocationField;
