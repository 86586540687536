import React, { useState, useEffect } from "react";

const TimeField = ({ input, changeHandler }) => {
  const [hours, setHours] = useState(input.hours || "");
  const [minutes, setMinutes] = useState(input.minutes || "");
  const [timePeriod, setTimePeriod] = useState(input.timePeriod || "am");

  const handleHoursChange = (e) => {
    setHours(e.target.value);
  };

  const handleMinutesChange = (e) => {
    setMinutes(e.target.value);
  };

  const handleTimePeriodChange = (e) => {
    setTimePeriod(e.target.value);
  };

  const validate = () => {
    return hours && minutes && timePeriod;
  };

  useEffect(() => {
    let valid = validate();
    changeHandler({ hours, minutes, timePeriod }, valid);
  }, [hours, minutes, timePeriod]);

  return (
    <div className="d-flex">
      <input
        type="number"
        className="underlined-field mr-3"
        placeholder="hh"
        name="hours"
        min="1"
        max="12"
        onChange={handleHoursChange}
        value={hours}
      />
      <input
        type="number"
        className="underlined-field mr-3"
        placeholder="mm"
        name="minutes"
        min="0"
        max="59"
        onChange={handleMinutesChange}
        value={minutes}
      />
      <select
        className="underlined-field"
        name="timePeriod"
        onChange={handleTimePeriodChange}
        value={timePeriod}
      >
        <option value="am">AM</option>
        <option value="pm">PM</option>
      </select>
    </div>
  );
};

export default TimeField;
