import React from "react";
import "./style.css";

const ChoicesField = ({ id, options, input, changeHandler, columns }) => {
  let buttonComponents = [];
  let rowClass = "choices__row";
  if (columns && parseInt(columns) === 2) {
    rowClass += " choices__row--two";
  } else if (columns && parseInt(columns) === 3) {
    rowClass += " choices__row--three";
  }

  const handleChoiceClick = (e) => {
    changeHandler(e.currentTarget.name, e.currentTarget.value);
  };

  const isChosen = (option) => {
    if (!input[id]) return;
    if (Array.isArray(input[id])) return input[id].includes(option.value);
    else return option.value === input[id];
  };

  if (options) {
    buttonComponents = options.map((option) => {
      let choiceClass = "choice__button mb-2";
      if (option.image) choiceClass += " choice__button--image";
      if (isChosen(option)) choiceClass += " is-chosen";
      return (
        <button
          className={choiceClass}
          value={option.value}
          key={option.id}
          name={id}
          onClick={handleChoiceClick}
        >
          {option.image && (
            <img
              src={option.image}
              alt={option.value}
              className="choice__button-image"
            />
          )}
          <span className="choice__button-title">{option.title}</span>
        </button>
      );
    });
  }

  return (
    <div className="choices">
      <div className={rowClass}>
        {buttonComponents &&
          buttonComponents.map((buttonComponent, index) => (
            <div className="choices__col" key={index}>
              {buttonComponent}
            </div>
          ))}
      </div>
    </div>
  );
};

export default ChoicesField;
