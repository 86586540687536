import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./App.css";
import { SessionProvider } from "./context/SessionContext";
import { PreferenceProvider } from "./context/PreferenceContext";
import { ExperimentProvider } from "./context/ExperimentContext";
import Routes from "./Routes";

const App = () => {
  return (
    <Router>
      <ExperimentProvider>
        <SessionProvider>
          <PreferenceProvider>
            <div className="App">
              <Routes />
            </div>
          </PreferenceProvider>
        </SessionProvider>
      </ExperimentProvider>
    </Router>
  );
};

export default App;
