import { Switch, Route } from "react-router-dom";
import SurveyView from "./views/SurveyView";
import ProcessingView from "./views/ProcessingView";
import RedirectView from "./views/RedirectView";
import useGoogleAnalytics from "./hooks/useGoogleAnalytics";

const Routes = () => {
  useGoogleAnalytics();
  return (
    <Switch>
      <Route exact path="/" component={SurveyView} />
      <Route exact path="/step/:slug" component={SurveyView} />
      <Route exact path="/processing" component={ProcessingView} />
      <Route exact path="/redirecting" component={RedirectView} />
    </Switch>
  );
};

export default Routes;
