import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { getChartPageURL } from "../../util/redirection";

const RedirectView = () => {
  useEffect(() => {
    window.location.replace(getChartPageURL());
  });

  return (
    <div className="pt-5">
      <Container>
        <Row>
          <Col xs={{ span: 10, offset: 1 }} md={{ span: 8, offset: 2 }}>
            <div className="text-center">
              <h1 className="h3">Loading your birth chart..</h1>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default RedirectView;
