import React, { useEffect } from "react";
import { useQuery } from "../hooks/useQuery";

const { createContext, useState } = require("react");

export const ExperimentContext = createContext();

export const ExperimentProvider = (props) => {
  const query = useQuery();
  const [experiment, setExperiment] = useState();

  useEffect(() => {
    let experimentStorage =
      localStorage.getItem("experiment") &&
      JSON.parse(localStorage.getItem("experiment"));
    let updatedExperiment = {};

    // Flow variant
    if (query.get("fv")) {
      updatedExperiment.fv = query.get("fv");
    } else if (experimentStorage && experimentStorage.fv) {
      updatedExperiment.fv = experimentStorage.fv;
    }

    // Price variant
    if (query.get("pv")) {
      updatedExperiment.pv = query.get("pv");
    } else if (experimentStorage && experimentStorage.pv) {
      updatedExperiment.pv = experimentStorage.pv;
    }
    setExperiment(updatedExperiment);
  }, []);

  useEffect(() => {
    if (!experiment) return;
    localStorage.setItem("experiment", JSON.stringify(experiment));
  }, [experiment]);

  return (
    <ExperimentContext.Provider value={[experiment, setExperiment]}>
      {props.children}
    </ExperimentContext.Provider>
  );
};
