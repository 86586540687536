import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { APP_NAME } from "../../util/constants";
import "./style.css";
import { Survey } from "../../util/survey";
import {
  getConfigFromStorage,
  updateConfigToStorage,
} from "../../util/storage";
import SurveyHeader from "../../components/SurveyHeader";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ChoicesField from "../../components/ChoicesField";
import TextField from "../../components/TextField";
import DateField from "../../components/DateField";
import TimeField from "../../components/TimeField";
import LocationField from "../../components/LocationField";

const SurveyView = () => {
  const { slug } = useParams();
  const history = useHistory();
  const survey = Survey(slug, history);
  const [input, setInput] = useState(getConfigFromStorage());

  const { step, showSubmitButton } = survey;
  const { id, title, fields, hideProgressBar, submitButtonText, meta } = step;
  const stepId = id;
  const stepInput = input[stepId] || {};
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    document.title = `${meta.title} - ${APP_NAME}`;
  }, [title, meta.title]);

  useEffect(() => {
    updateConfigToStorage(input);
  }, [input]);

  const handleChange = (name, value, valid) => {
    setInput((prevState) => ({
      ...prevState,
      [stepId]: {
        ...prevState[stepId],
        [name]: value,
      },
    }));
    setIsValid(valid);
  };

  const handleSingleChoiceChange = (name, value) => {
    handleChange(name, value);
    setTimeout(() => {
      survey.navigateToNextStep();
    }, 200);
  };

  const handleMultipleChoiceChange = (name, value) => {
    setInput((prevState) => {
      let updatedValue = [];
      if (prevState[stepId] && prevState[stepId][name] && "none" !== value) {
        let currentValue = prevState[stepId][name];
        if (currentValue.includes(value)) {
          updatedValue = currentValue.filter((val) => val !== value);
        } else {
          updatedValue = [...currentValue, value];
        }
        updatedValue = updatedValue.filter((val) => "none" !== val);
      } else {
        updatedValue = [value];
      }
      return {
        ...prevState,
        [stepId]: {
          ...prevState[stepId],
          [name]: updatedValue,
        },
      };
    });
  };

  const handleDateChange = (dateObject, valid) => {
    setInput((prevState) => ({
      ...prevState,
      [stepId]: {
        ...prevState[stepId],
        ...dateObject,
      },
    }));
    setIsValid(valid);
  };

  const handleTimeChange = (timeObject, valid) => {
    setInput((prevState) => ({
      ...prevState,
      [stepId]: {
        ...prevState[stepId],
        ...timeObject,
      },
    }));
    setIsValid(valid);
  };
  const handleLocationChange = (locationObject, valid) => {
    setInput((prevState) => ({
      ...prevState,
      [stepId]: {
        ...prevState[stepId],
        ...locationObject,
      },
    }));
    setIsValid(valid);
  };

  const handleSubmit = () => {
    survey.navigateToNextStep();
  };

  let fieldComponents = [];
  if (fields) {
    fieldComponents = fields.map((field) => {
      let { id, type, options, columns, optional, placeholder } = field;
      switch (type) {
        case "date":
          return (
            <div className="mb-3">
              <DateField
                id={id}
                input={stepInput}
                changeHandler={handleDateChange}
              />
            </div>
          );
        case "time":
          return (
            <div className="mb-3">
              <TimeField
                id={id}
                input={stepInput}
                changeHandler={handleTimeChange}
              />
            </div>
          );
        case "location":
          return (
            <div className="mb-3">
              <LocationField
                className="large-field"
                id={id}
                input={stepInput}
                changeHandler={handleLocationChange}
              />
            </div>
          );
        case "text":
          return (
            <div className="mb-3">
              <TextField
                type="text"
                id={id}
                input={stepInput}
                placeholder={placeholder}
                className="large-field"
                changeHandler={handleChange}
                key={id}
              />
            </div>
          );
        case "multipleChoice":
          if (optional) {
            options = [
              ...options,
              {
                id: "none",
                title: "None",
                value: "none",
              },
            ];
          }
          return (
            <ChoicesField
              id={id}
              options={options}
              changeHandler={handleMultipleChoiceChange}
              key={id}
              input={stepInput}
              columns={columns}
              optional={optional}
            />
          );
        default:
          return (
            <ChoicesField
              id={id}
              options={options}
              changeHandler={handleSingleChoiceChange}
              key={id}
              input={stepInput}
              columns={columns}
            />
          );
      }
    });
  }

  const InputView = (
    <>
      <header className="d-flex justify-content-center mb-4">
        <strong className="survey__question">{title}</strong>
      </header>
      <div className="survey__input">
        <div className="mb-4">
          <Row>
            <Col md={{ span: 10, offset: 1 }}>
              {fieldComponents.map((fieldComponent, index) => (
                <div className="survey__row mb-3" key={index}>
                  {fieldComponent}
                </div>
              ))}
            </Col>
          </Row>
        </div>
      </div>
    </>
  );

  return (
    <Container>
      <Row>
        <Col md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }}>
          <div className="survey">
            {!hideProgressBar && <SurveyHeader className="mb-5"></SurveyHeader>}
            {InputView}
            {showSubmitButton && (
              <Row>
                <Col xs={{ span: 10, offset: 1 }} md={{ span: 10, offset: 1 }}>
                  {isValid && (
                    <button
                      onClick={handleSubmit}
                      className="btn btn--pink btn--block"
                      disabled={!isValid}
                    >
                      {(submitButtonText && submitButtonText) || "Continue"}
                    </button>
                  )}
                </Col>
              </Row>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default SurveyView;
