import React, { useEffect, useState } from "react";

const DateField = ({ input, changeHandler, loadHandler }) => {
  const [month, setMonth] = useState(input.dateMonth || "");
  const [day, setDay] = useState(input.dateDay || "");
  const [year, setYear] = useState(input.dateYear || "");

  const handleMonthChange = (e) => {
    setMonth(e.target.value);
  };

  const handleDayChange = (e) => {
    setDay(e.target.value);
  };

  const handleYearChange = (e) => {
    setYear(e.target.value);
  };

  const validate = () => {
    return month && day && year;
  };

  useEffect(() => {
    let valid = validate();
    changeHandler({ dateMonth: month, dateDay: day, dateYear: year }, valid);
  }, [month, day, year]);

  let monthOptions = [];
  for (let i = 1; i <= 12; i++) {
    monthOptions.push(
      <option key={i} value={i}>
        {("0" + i).slice(-2)}
      </option>
    );
  }

  let dayOptions = [];
  for (let i = 1; i <= 31; i++) {
    dayOptions.push(
      <option key={i} value={i}>
        {("0" + i).slice(-2)}
      </option>
    );
  }

  let yearOptions = [];
  for (let i = 2021; i >= 1900; i--) {
    yearOptions.push(
      <option key={i} value={i}>
        {i}
      </option>
    );
  }

  return (
    <div className="d-flex">
      <select
        className="underlined-field mr-3"
        placeholder="MM"
        value={month}
        onChange={handleMonthChange}
        name="dateMonth"
      >
        <option value="" disabled defaultValue="MM">
          MM
        </option>
        {monthOptions}
      </select>
      <select
        className="underlined-field mr-3"
        placeholder="DD"
        value={day}
        onChange={handleDayChange}
        name="dateDay"
      >
        <option value="" disabled defaultValue="DD">
          DD
        </option>
        {dayOptions}
      </select>
      <select
        className="underlined-field"
        placeholder="YYYY"
        value={year}
        onChange={handleYearChange}
        name="dateYear"
      >
        <option value="" disabled defaultValue="YYYY">
          YYYY
        </option>
        {yearOptions}
      </select>
    </div>
  );
};

export default DateField;
