import React, { useState, createContext, useEffect } from "react";
import axios from "axios";
import { getUserLocales } from "get-user-locale";
import {
  getCountryCodeFromStorage,
  updateCountryCodeToStorage,
  getLocaleFromStorage,
  updateLocaleToStorage,
} from "../util/storage";

export const PreferenceContext = createContext();

export const PreferenceProvider = (props) => {
  const [preferences, setPreferences] = useState({
    countryCode: getCountryCodeFromStorage() || "US",
    locale: getLocaleFromStorage() || "en-US",
  });
  const { countryCode, locale } = preferences;

  useEffect(() => {
    const initEffect = async () => {
      if (!localStorage.getItem("countryCode")) {
        let code = "US";
        let loc = "en-US";

        const locales = getUserLocales();
        if (locales && locales[0]) loc = locales[0];

        try {
          const response = await axios.get(
            "https://extreme-ip-lookup.com/json/"
          );
          if (response.status === 200 && response.data.countryCode) {
            code = response.data.countryCode;
          }
        } catch (err) {
          console.error(err);
        }
        setPreferences((prevState) => ({
          ...prevState,
          countryCode: code,
          locale: loc,
        }));
      }
    };
    initEffect();
  }, []);

  useEffect(() => {
    updateCountryCodeToStorage(countryCode);
  }, [countryCode]);

  useEffect(() => {
    updateLocaleToStorage(locale);
  }, [locale]);

  return (
    <PreferenceContext.Provider value={[preferences, setPreferences]}>
      {props.children}
    </PreferenceContext.Provider>
  );
};
