import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import gaAnalytics from "../util/ga";

const useGoogleAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    gaAnalytics.init();
  }, []);

  useEffect(() => {
    const currentPath = location.pathname + location.search;
    gaAnalytics.sendPageview(currentPath);
    window.dataLayer.push({ event: "optimize.activate" });
  }, [location]);
};

export default useGoogleAnalytics;
