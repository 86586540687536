const structure = {
  phases: [
    {
      id: "birthDetails",
      title: "Birth details",
      steps: [
        {
          id: "birthDate",
          slug: "birth-date",
          title: "When where you born?",
          meta: {
            title: "Birth date",
          },
          fields: [
            {
              id: "birthDate",
              type: "date",
            },
          ],
        },
        {
          id: "birthTime",
          slug: "birth-time",
          title: "At what time where you born?",
          meta: {
            title: "Birth time",
          },
          fields: [
            {
              id: "birthTime",
              type: "time",
            },
          ],
        },
        {
          id: "birthLocation",
          slug: "birth-location",
          title: "Where were you born?",
          meta: {
            title: "Birth location",
          },
          fields: [
            {
              id: "birthLocation",
              type: "location",
            },
          ],
        },
        {
          id: "gender",
          slug: "gender",
          title: "What is your gender?",
          meta: {
            title: "Gender",
          },
          fields: [
            {
              id: "gender",
              type: "singleChoice",
              options: [
                {
                  id: "female",
                  title: "Female",
                  value: "female",
                },
                {
                  id: "male",
                  title: "Male",
                  value: "male",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: "profile",
      title: "Profile",
      steps: [
        {
          id: "firstName",
          slug: "first-name",
          title: "What is your first name?",
          meta: {
            title: "First name",
          },
          fields: [
            {
              id: "firstName",
              type: "text",
              placeholder: "Your first name",
            },
          ],
        },
        {
          id: "lastName",
          slug: "last-name",
          title: "What is your last name?",
          meta: {
            title: "Last name",
          },
          loaderAfter: true,
          fields: [
            {
              id: "lastName",
              type: "text",
              placeholder: "Your last name",
            },
          ],
        },
      ],
    },
  ],
};

export default structure;
